import {
  Banner,
  Box,
  Container,
  Heading,
  Helmet,
  Interfaces,
  Layout,
  Link,
  PreStyledComponents,
  Row,
  Theme
} from "@life-without-barriers/react-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import styled from "styled-components"

const { StandardButton } = PreStyledComponents
const { corporateThemeFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
  }
}

const Events = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Events | ${siteMetadata.title}`}
        description="Our events."
        image={bannerImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout theme={corporateThemeFull}>
        <Banner image={bannerImage} title="Events" />
        <Container>
          <Row>
            <Box className="center tc">
              <Heading size={2} className="tc ts-display-4">
                Read about or register for the latest events hosted and promoted
                by Life Without Barriers.
              </Heading>
              <Link
                className="mt4 dib no-underline"
                to={
                  "https://www.eventbrite.com.au/o/life-without-barriers-18159721409"
                }
              >
                <StyledButton className="center">
                  {"Visit Eventbrite"}
                </StyledButton>
              </Link>
            </Box>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

const StyledButton = styled(StandardButton)`
  background-color: ${({ theme }: Interfaces.ThemedProps) => theme.medium};

  &:focus,
  &:hover {
    background-color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};
  }
`

export const query = graphql`
  {
    bannerImage: file(relativePath: { regex: "/events-banner.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default Events
